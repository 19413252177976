// @auto-fold regex /function / /function()/ /var * = /
import axios from 'axios'
import {MagbizGeneral} from './Magbiz';
import {Geo} from './BGO';
import settings from '../settings'

const GOOGLE_API_KEY = 'AIzaSyA_5pfs99R-CU9zEeXd2Oi8c7cS81-9vBc'        //Premium Google Map ID
//const GOOGLE_API_KEY = 'AIzaSyCVQh1w3ZcNPy9jkfFb9nuC3t9hfTu8k48'
const GoogleMapsLoader = require('google-maps');

export default class GMap {

  static initialized = false
  static drawingManager = null
  static infoWindow = null
  static maps = {}
  static mapObjs = {}
  static callback = {}
  static mapType = MagbizGeneral.cookieValue("googleMapType","roadmap")
  static mapZoom = parseInt(MagbizGeneral.cookieValue("googleMapZoom",10))
  static center_lat = MagbizGeneral.cookieValue("center_lat",13.7730311784028)
  static center_lng = MagbizGeneral.cookieValue("center_lng",100.676131122338)

  //####### Simulation ############
  static runSimulate = false
  static currentSimulate = 0
  static vehicleSimulatingObj = null

  static init(lang){
    if(!this.initialized){
      this.initialized = true
      GoogleMapsLoader.KEY = GOOGLE_API_KEY
      GoogleMapsLoader.LANGUAGE = lang
      GoogleMapsLoader.VERSION = 'weekly'//'3.44'
      GoogleMapsLoader.LIBRARIES = ['drawing','places','geometry']
      const me = this
      GoogleMapsLoader.load(function(google) {
        console.log("Google Map was initialized :", window.google)
        window.google.maps.Polygon.prototype.enableCoordinatesChangedEvent = function () {
            var me = this,
            isBeingDragged = false,
            triggerCoordinatesChanged = function () {
                window.google.maps.event.trigger(me, 'coordinates_changed');
            };

            window.google.maps.event.addListener(me, 'dragstart', function () {
                isBeingDragged = true;
            });

            window.google.maps.event.addListener(me, 'dragend', function () {
                triggerCoordinatesChanged();
                isBeingDragged = false;
            });

            var paths = me.getPaths();
            paths.forEach(function (path, i) {
                window.google.maps.event.addListener(path, "insert_at", function () {
                    triggerCoordinatesChanged();
                });
                window.google.maps.event.addListener(path, "set_at", function () {
                    if (!isBeingDragged) {
                        triggerCoordinatesChanged();
                    }
                });
                window.google.maps.event.addListener(path, "remove_at", function () {
                    triggerCoordinatesChanged();
                });
            });
        }
        for(let containerClass in me.maps){
          me.loadMap(containerClass)
        }
      });
    }
  }

  static available() {
    return Boolean(window.google)
  }

  static clearMapLoading(containerClass){
    delete this.maps[containerClass]
  }

  static getMap(containerClass){
    return this.maps[containerClass]
  }

  static loadMap(containerClass) {
    if(this.available()){
      const mapContainer = document.querySelector("."+containerClass)
      if(!this.maps[containerClass] && mapContainer){

        if(isNaN(parseInt(this.mapZoom))) this.mapZoom = 10

      	const settings = {
      			zoom: this.mapZoom,
      			center: new window.google.maps.LatLng(this.center_lat, this.center_lng),
      			mapTypeId: this.mapType,
      			scrollwheel: true,
      			draggable: true,
      			/*styles: [{"featureType":"landscape.natural","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#e0efef"}]},
      								{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"hue":"#1900ff"},{"color":"#c0e8e8"}]},
      								{"featureType":"road","elementType":"geometry","stylers":[{"lightness":100},{"visibility":"simplified"}]},
      								{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},
      								{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"},{"lightness":700}]},
      								{"featureType":"water","elementType":"all","stylers":[{"color":"#7dcdcd"}]}],*/
      			mapTypeControl: true,
      			mapTypeControlOptions: {
      				style: window.google.maps.MapTypeControlStyle.DEFAULT,
      				position: window.google.maps.ControlPosition.RIGHT_TOP
      			},
      			navigationControl: true,
      			navigationControlOptions: {style: window.google.maps.NavigationControlStyle.SMALL},
      			scaleControl: true,
      			streetViewControl: true,
      			rotateControl: true,
      			fullscreenControl: true,
      			fullscreenControlOptions: {
      				position: window.google.maps.ControlPosition.LEFT_BOTTOM
      			}
      	};
        this.maps[containerClass] = new window.google.maps.Map(mapContainer, settings)
        this.bindMapStateListener(this.maps[containerClass])
        console.log("Google Map Loading : ", containerClass)
      }
    }else{
      this.maps[containerClass] = null
    }
    return this.maps[containerClass]
  }

  static resize(map){
  	if(map){
  		console.log("Google Map Repaint");
  		const center = map.getCenter();
  		window.google.maps.event.trigger(map, 'resize');
  		map.setCenter(center);
  		map.setZoom(map.getZoom());
  	}
  }

  static bindMapStateListener(map) {
    const me = this

    this.drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.NULL,
      drawingControl: false,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon']
      },
      polygonOptions: {
        fillColor: '#EAC627',
        fillOpacity: 0.5,
        strokeColor: '#EAC627',
        strokeWeight: 2,
      }
    });
    this.drawingManager.setMap(map);
    window.google.maps.event.addListener(this.drawingManager,'polygoncomplete',function(polygon) {
       polygon.id = 0;
       me.keepData([polygon], {group:"field_working"})
       if(me.callback.polygonAddComplete){
         me.callback.polygonAddComplete(polygon)
       }
    });

    window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
  		MagbizGeneral.setCookie('googleMapType',map.getMapTypeId(),365);
  	});

  	window.google.maps.event.addListener( map, 'zoom_changed', function() {
  		const oldZoom= MagbizGeneral.cookieValue('googleMapZoom',0);
  		MagbizGeneral.setCookie('googleMapZoom',map.getZoom(),365);
      if(me.callback.mapZoomOrCenterChanged){
        MagbizGeneral.delay(function(){me.callback.mapZoomOrCenterChanged(map.getCenter(), map.getCenter(), 0, oldZoom, map.getZoom());},500);
      }
  	});

  	window.google.maps.event.addListener( map, 'center_changed', function() {
  		MagbizGeneral.delay(function(){
  			let oldLat = MagbizGeneral.cookieValue('center_lat',0);
  			let oldLng = MagbizGeneral.cookieValue('center_lng',0);
  			let oldLocation = new window.google.maps.LatLng(oldLat, oldLng);
  			MagbizGeneral.setCookie('center_lat',map.getCenter().lat(),365);
  			MagbizGeneral.setCookie('center_lng',map.getCenter().lng(),365);
  			if (me.callback.mapZoomOrCenterChanged) {
  				var distance = window.google.maps.geometry.spherical.computeDistanceBetween (map.getCenter(), oldLocation);
  				if(distance >= 100){
  					MagbizGeneral.delay(function(){me.callback.mapZoomOrCenterChanged(oldLocation, map.getCenter(), distance, map.getZoom(), map.getZoom());},500);
  				}
  			}
  		},100);
  	});

  	window.google.maps.event.addListenerOnce(map, 'idle', function(){
  		console.log("Google Map Loaded");
  		if (me.callback.mapLoad) {
  			me.callback.mapLoad(map);
  		}
  	});

  	window.google.maps.event.addListener( map, 'click', function(event) {
  		const location = {lat:event.latLng.lat(), lng:event.latLng.lng()};
  		if (me.callback.mapClick) {
  			me.callback.mapClick(location);
  		}
  	});

  	window.google.maps.event.addListener( map, 'rightclick', function(event) {
  		const location = {lat:event.latLng.lat(), lng:event.latLng.lng()};
  		if (me.callback.mapRightClick) {
  			me.callback.mapRightClick(location);
  		}
  	})

  }

  static setCallback(name, c){
    this.callback[name] = c
  }

  // ################# Map Object Management
  static keepData(obj, group, mode, index){
  	if(mode === "add"){
  		this.mapObjs[group].push(obj);
  	}else if(typeof index !== "undefined"){	//mode = update
  		this.mapObjs[group][index].setMap(null);
  		this.mapObjs[group][index] = obj;
  	}else{
  		this.clearData(group);
  		this.mapObjs[group] = obj;
  	}
  }

  static clearData(group){
  	if(this.mapObjs[group]){
  		if(group.length > 8 && group.substr(group.length - 8) === "_cluster"){
  			this.mapObjs[group].clearMarkers();
  		}else {
  			for (let i = 0; i < this.mapObjs[group].length; i++ ) {
          if(this.mapObjs[group][i]){
            this.mapObjs[group][i].setMap(null);
          }
  			}
  			delete this.mapObjs[group]
  		}
  	}
  }

  static clearMapObj(group, index){
  	if(this.mapObjs[group]){
  		if(typeof index !== "undefined"){
  			if(this.mapObjs[group][index]){
  				this.mapObjs[group][index].setMap(null);
  				this.mapObjs[group].splice(index, 1);
  			}
  		}else{
  			this.clearData(group);
  		}
  	}
  }

  static getMapObjInfo(group, id){
  	let index = -1;
  	let marker;
  	let cluster=false;
  	if(this.mapObjs[group+"_cluster"]){
  		cluster = true;
  		for(let i in this.mapObjs[group+"_cluster"].markers_){
  			if(this.mapObjs[group+"_cluster"].markers_[i].id === id){
  				marker = this.mapObjs[group+"_cluster"].markers_[i];
  				index = i;
  				break;
  			}
  		}
  	}else{
  		for(let i in this.mapObjs[group]){
  			if(this.mapObjs[group][i].id === id){
  				marker = this.mapObjs[group][i];
  				index = i;
  				break;
  			}
  		}
  	}
  	return {index:index, marker:marker, cluster:cluster};
  }

  static getMapObj(group, id){
    const geoObj = this.getMapObjInfo(group, id);
    if(geoObj.index !== -1){
  		return this.mapObjs[group][geoObj.index]
  	}else{
      return null
    }
  }

  static mapSetCenter(map, lat, lng){
    map.panTo(new window.google.maps.LatLng(lat, lng))
  }

  static mapGetZoom(map){
    return map.getZoom()
  }

  static mapSetZoom(map, zoom){
    map.setZoom(zoom)
  }

  static mapSetBound(map, bound){
  	 var bounds = new window.google.maps.LatLngBounds();
  	 if(typeof bound.minLat !== "undefined"){
  		 var point1 = new window.google.maps.LatLng(bound.minLat, bound.minLon);
  		 var point2 = new window.google.maps.LatLng(bound.maxLat, bound.maxLon);
  		 bounds.extend(point1);
  		 bounds.extend(point2);
  	 }else{
      if(bound.length === 0) return
  		for(let i=0;i<bound.length;++i){
  			if(typeof bound[i].lat !== "function"){
  				bounds.extend(new window.google.maps.LatLng(bound[i].lat, bound[i].lng));
  			}else{
  				bounds.extend(bound[i]);
  			}
  		}
  	 }
  	 map.fitBounds(bounds);
  }

  static async reverseGeoCodingAsync(lat, lng){
    let addr = null
    const geocoder = new window.google.maps.Geocoder()
    const response = await geocoder.geocode({'latLng': new window.google.maps.LatLng(lat,lng)})
    if(response && response.results && response.results.length > 0){
      const resp = response.results
      addr = {zipcode:"",country:"",province:"",city:"",district:"",address:resp[0].formatted_address}
      for(let i=0;i<resp[0].address_components.length;++i){
        const addr_cp = resp[0].address_components[i]
        if(addr_cp.types.length === 1){
          if  (addr_cp.types[0] === "postal_code") {
           addr.zipcode = addr_cp.long_name
         }
       }else if(addr_cp.types.length === 2){
          if(addr_cp.types[0] === "country" && addr_cp.types[1] === "political") {
            addr.country = addr_cp.long_name
          }else if(addr_cp.types[0] === "administrative_area_level_1" && addr_cp.types[1] === "political"){
            addr.province = addr_cp.long_name
          }else if(addr_cp.types[0] === "administrative_area_level_2" && addr_cp.types[1] === "political"){
            addr.city = addr_cp.long_name
          }else if(addr_cp.types[0] === "locality" && addr_cp.types[1] === "political" && addr_cp.long_name !== "Bangkok" && addr_cp.long_name !== "กรุงเทพมหานคร"){
            addr.district = addr_cp.long_name
          }
        }else if(addr_cp.types.length === 3){
          if (addr_cp.types[0] === "political" && addr_cp.types[1] === "sublocality" && addr_cp.types[2] === "sublocality_level_1") {
            addr.city = addr_cp.long_name
          }else if (addr_cp.types[0] === "political" && addr_cp.types[1] === "sublocality" && addr_cp.types[2] === "sublocality_level_2") {
            addr.district = addr_cp.long_name
          }
        }
      }
      addr.address_no = addr.address.replace(addr.district, "").replace(addr.city, "").replace(addr.province, "").replace(addr.zipcode, "").replace(addr.country, "").trim()
      if(addr.city) {addr.city = addr.city.replace("อำเภอ ","").replace("เขต ","").replace("อำเภอ","").replace("เขต","")}
      if(addr.district) {addr.district = addr.district.replace("ตำบล ","").replace("แขวง ","").replace("ตำบล","").replace("แขวง","")}
    }
    return addr
  }

  // Google API
  static reverseGeoCoding(lat, lng, callback){
    let tryNum = 1
  	const gf = () => {
  		var geocoder = new window.google.maps.Geocoder();
  		geocoder.geocode({'latLng': new window.google.maps.LatLng(lat,lng)}, function(resp, status) {
  		  if (status === window.google.maps.GeocoderStatus.OK) {
          let addr = null
    			if (resp[0]) {
            addr = {portal:"",country:"",province:"",city:"",district:"",address:resp[0].formatted_address}
          	for(let i=0;i<resp[0].address_components.length;++i){
              const addr_cp = resp[0].address_components[i]
          		if(addr_cp.types.length === 1){
          			if  (addr_cp.types[0] === "postal_code") {
          			 addr.portal = addr_cp.long_name
          		 }
             }else if(addr_cp.types.length === 2){
          			if(addr_cp.types[0] === "country" && addr_cp.types[1] === "political") {
          				addr.country = addr_cp.long_name
          			}else if(addr_cp.types[0] === "administrative_area_level_1" && addr_cp.types[1] === "political"){
          				addr.province = addr_cp.long_name
          			}else if(addr_cp.types[0] === "administrative_area_level_2" && addr_cp.types[1] === "political"){
          				addr.city = addr_cp.long_name
          			}else if(addr_cp.types[0] === "locality" && addr_cp.types[1] === "political" && addr_cp.long_name !== "Bangkok" && addr_cp.long_name !== "กรุงเทพมหานคร"){
          				addr.district = addr_cp.long_name
          			}
          		}else if(addr_cp.types.length === 3){
          			if (addr_cp.types[0] === "political" && addr_cp.types[1] === "sublocality" && addr_cp.types[2] === "sublocality_level_1") {
          				addr.city = addr_cp.long_name
          			}else if (addr_cp.types[0] === "political" && addr_cp.types[1] === "sublocality" && addr_cp.types[2] === "sublocality_level_2") {
          				addr.district = addr_cp.long_name
          			}
          		}
          	}
            if(addr.city) {addr.city = addr.city.replace("อำเภอ ","").replace("เขต ","")}
            if(addr.district) {addr.district = addr.district.replace("ตำบล ","").replace("แขวง ","")}
            addr.address_no = addr.address.replace(addr.district, "").replace(addr.city, "").replace(addr.province, "").replace(addr.portal, "").replace(addr.country, "").trim()
    			}
          if(callback){
            callback(addr);
          }
  		  }else{
          if(tryNum < 3){
            tryNum+=1
            setTimeout(gf, 200);
          }else{
            if(callback){
    					callback(null);
    				}
          }
  		  }
  		});
  	}
  	gf();

  }

  // ################# Marker
  static addMarker(map, obj, options){
    if(options.marker) {obj = {...obj, marker:options.marker}}
    const geoObj = this.placeMarker(map, obj)
    if(geoObj){
      if(this.mapObjs[options.group+"_cluster"]){
        this.mapObjs[options.group+"_cluster"].addMarker(geoObj.marker)
      }else{
        this.keepData(geoObj.marker, options.group, "add")
        if(geoObj.label){
          this.keepData(geoObj.label, options.group+"_label", "add")
        }
        if(geoObj.caption){
          this.keepData(geoObj.caption, options.group+"_caption", "add")
        }
        if(geoObj.radius){
          this.keepData(geoObj.radius, options.group+"_radius", "add")
        }
      }
    }
  }

  static removeMarker(obj, options){
  	const geoObj = this.getMapObjInfo(options.group, obj.id)
  	if(geoObj.index !== -1){
  		if(geoObj.cluster){
  			this.mapObjs[options.group+"_cluster"].removeMarker(geoObj.marker);
  		}else{
        this.clearMapObj(options.group, geoObj.index)
        this.clearMapObj(options.group+"_label", geoObj.index)
        this.clearMapObj(options.group+"_caption", geoObj.index)
        this.clearMapObj(options.group+"_radius", geoObj.index)
  		}
  	}
  }

  static drawMarkers(map, objs, options){
    this.keepData([], options.group);
    this.keepData([], options.group+"_label");
    this.keepData([], options.group+"_caption");
    this.keepData([], options.group+"_radius");
    if(objs){
      for(let i=0;i<objs.length;++i){
        if(options.marker) {objs[i] = {...objs[i], marker:options.marker}}
        const geoObj = this.placeMarker(map, objs[i], options)
        if(geoObj){
          this.keepData(geoObj.marker, options.group, "add")
          if(geoObj.label){
            this.keepData(geoObj.label, options.group+"_label", "add")
          }
          if(geoObj.caption){
            this.keepData(geoObj.caption, options.group+"_caption", "add")
          }
          if(geoObj.radius){
            this.keepData(geoObj.radius, options.group+"_radius", "add")
          }
        }
    	}
      if(options.cluster){
        if(!options.clusterGridSize){
          options.clusterGridSize=50
        }
      	//API_makeCluster(map, markers, options);		//disable or enable marker option
      }
    }
  }

  //Options => hideLabel, width, textWeight, textSize, textColor, textOpacity, textBorder, textBorderRadius, textBg
  //           captionWidth, captionWeight, captionSize, captionColor, captionOpacity, captionBorder, captionBorderRadius, captionBg
  static placeMarker(map, obj, options) {
  	const point = new window.google.maps.LatLng((obj.marker.lat ? obj.marker.lat : obj.lat), (obj.marker.lng ? obj.marker.lng : obj.lng));

    let radius = null
    if(obj.radius){
      radius = new window.google.maps.Circle({
         strokeColor: "#bdbdbd",
         strokeOpacity: 1,
         strokeWeight: 1,
         fillColor: "#f2f2f2",
         fillOpacity: 0.05,
         center: point,
         radius: obj.radius,
         zIndex:9,
         clickable:false
      })
      radius.id=obj.id
      radius.setMap(map)
    }

    const scale = (obj.draggable ? obj.marker.scaleDraggable : obj.marker.scale)
    let icon =  {
       url: obj.marker.image,
       size: new window.google.maps.Size(obj.marker.width, obj.marker.height),
       origin: new window.google.maps.Point(0, 0),
       anchor: new window.google.maps.Point(obj.marker.width*scale/2, obj.marker.height*scale), // Default anchor AboveCenter
       scaledSize: new window.google.maps.Size(obj.marker.width*scale, obj.marker.height*scale)
    }
    if(obj.marker.anchor){
      if(obj.marker.anchor === "Center"){
        icon.anchor = new window.google.maps.Point(obj.marker.width*scale/2, obj.marker.height*scale/2)
      }
    }
  	const markerOpt = {
  		position: point,
      //label: obj.marker.label,
  		draggable: (obj.draggable ? obj.draggable : false),
  		raiseOnDrag: true,
  		icon:icon,
      zIndex:(obj.marker.isField ? 10 : (obj.marker.type === "vehicle" ? 19 : 11))
  	}
    if(obj.draggable){
      markerOpt.animation = window.google.maps.Animation.DROP
    }

    let captionStyle = "white-space:nowrap;overflow: hidden;text-overflow: ellipsis;font-weight:"+(options && options.captionWeight ? options.captionWeight : "normal")+";font-size:"+(options && options.captionSize ? options.captionSize : "10")+"px;color:"+(options && options.captionColor ? options.captionColor : "black")+";width:"+(options && options.captionWidth ? options.captionWidth : "140")+"px;opacity:"+(options && options.captionOpacity ? options.captionOpacity : "1")+";text-align:center;padding:1px 2px;"
    if(options && options.captionBorder) captionStyle += "border:"+options.captionBorder+";"
    if(options && options.captionBorderRadius) captionStyle += "border-radius:"+options.captionBorderRadius+";"
    if(options && options.captionBg) captionStyle += "background:"+options.captionBg+";"
    let labelStyle = "white-space:nowrap;overflow: hidden;text-overflow: ellipsis;"
    labelStyle += "font-weight:"+(options && options.textWeight ? options.textWeight : "normal")+";font-size:"+(options && options.textSize ? options.textSize : "10")+"px;color:"+(obj.marker.textColor ? obj.marker.textColor : (options && options.textColor ? options.textColor : "black"))+";max-width:"+(options && options.width ? options.width : "140")+"px;opacity:"+(options && options.textOpacity ? options.textOpacity : "1")+";text-align:center;padding:2px 5px;"
    if(options && options.textBorder) labelStyle += "border:"+options.textBorder+";"
    if(options && options.textBorderRadius) labelStyle += "border-radius:"+options.textBorderRadius+";"
    if(options && options.textBg) labelStyle += "background:"+options.textBg+";"
    let marker = null
    let label = null
    let caption = null
    if(obj.marker.type === "vehicle"){
      const rotate = "rotation: "+obj.marker.rotate+"deg;transform: rotate("+obj.marker.rotate+"deg);-webkit-transform: rotate("+obj.marker.rotate+"deg);-moz-transform: rotate("+obj.marker.rotate+"deg);filter: progid:DXImageTransform.Microsoft.BasicImage(rotation="+(obj.marker.rotate/90)+");";
      const width = obj.marker.width*scale
      const height = obj.marker.height*scale
      let vehicle_content = "<div style='position:relative;'>";
    	vehicle_content = "<img src='"+obj.marker.image+"' style='width:"+width+"px;height:"+height+"px;"+rotate+"'/>";
    	vehicle_content += "</div>"
      marker = new CustomMarker(point, map, {
        content: vehicle_content,
        offsetLeft: -width/2,
        offsetTop: -height/2,
        zIndex:(obj.marker.isField ? 10 : (obj.marker.type === "vehicle" ? 19 : 11))
      })
      marker.id = obj.id

      if(options && (!options.hideLabel || obj.marker.forceLabel) && obj.marker.label){
        label = new CustomMarker(point, map, {
          content: "<div style='"+labelStyle+"'>"+obj.marker.label+"</div>",
          offsetLeft: -10,
          offsetTop: 12,
          clickable:false
        })
        label.id = obj.id
      }
    }else{
      marker = new window.google.maps.Marker(markerOpt)
      marker.setMap(map)
      marker.id = obj.id
      if(options && !options.hideLabel && obj.marker.label){
        label = new CustomMarker(point, map, {
          content: "<div style='"+labelStyle+"'>"+obj.marker.label+"</div>",
          offsetLeft: -10,//-(options.width ? options.width/2 : 70)
          clickable:false
        })
        label.id = obj.id
      }
      if(options && obj.marker.caption){
        caption = new CustomMarker(point, map, {
          content: "<div style='"+captionStyle+"'>"+obj.marker.caption+"</div>",
          offsetLeft: -(options.captionWidth ? options.captionWidth/2 : 70),
          offsetTop: -(obj.marker.height + (obj.marker.captionSize ? obj.marker.captionSize : 10) + 5),
          clickable:false
        })
        caption.id = obj.id
      }
    }

    const createContentItem = (label, value, color) => {
      if(!value) return ""
      if(options.translate || options.tlang){
        let content = "<tr>"
        content += "<td style='font-size:12px;padding:2px 5px;font-weight:bold;width:100px;'>"+(options.translate ? options.translate(label) : options.tlang({"id":label})  )+"</td>"
        content += "<td style='font-size:12px;padding:2px 5px;width:5px;'> : </td>"
        content += "<td style='font-size:12px;padding:2px 5px;border-bottom:1px solid #fafafa;color:"+(color ? color : "#888")+";'>"+value+"</td>"
        content += "</tr>"
        return content
      }else{
        return ""
      }
    }

    window.google.maps.event.addListener(marker, "click", (e) => {

      if(this.infoWindow) {this.infoWindow.setMap(null)}
      this.infoWindow = new window.google.maps.InfoWindow()
      let hasContent = false
      let content = "<div style='min-width:300px;'>"

      if(obj.marker.isField){

        try{

          if(options.master_fixed && obj.field_no){
            content += "<div style='font-size:18px;padding:2px 5px;font-weight:bold;border-bottom:1px solid #f2f2f2;margin-bottom:5px;'>"+obj.field_no+"</div>"
            content += "<table style='width:100%;'>"
            content += createContentItem("field.yearly", obj.yearly)
            content += createContentItem("field.doc_area", MagbizGeneral.toMoney(obj.doc_area,1)+" ไร่")
            content += createContentItem("field.gps_area", MagbizGeneral.toMoney(obj.gps_area,1)+" ไร่")
            content += createContentItem("field.produce_weight", MagbizGeneral.toMoney(obj.produce_weight,1)+" ตัน")
            content += createContentItem("field.farmer", (obj.farmer_name ? obj.farmer_quota+" - "+obj.farmer_name : ""))
            content += createContentItem("field.staff", obj.staff_name)
            if(options.master_fixed.plant_type_object && options.master_fixed.plant_type_object[obj.plant_type]){
              content += createContentItem("field.plant_type2" , options.master_fixed.plant_type_object[obj.plant_type].text )
            }else{
              content += createContentItem("field.plant_type" , obj.plant_type )
            }
            content += createContentItem("field.plant_age", (options.master_fixed.plant_age_object[obj.plant_age] ? options.master_fixed.plant_age_object[obj.plant_age].text : obj.plant_age))
            if(obj.jsn_info_brix_avg){
              content += createContentItem("farm.brix.avg_brix", obj.jsn_info_brix_avg)
            }
            content += createContentItem("field.coordinate" , obj.lat+", "+obj.lng )
            if(options.master_fixed.field_status_object[obj.status]){
              content += createContentItem("status", options.master_fixed.field_status_object[obj.status].text, options.master_fixed.field_status_object[obj.status].color)
            }
            content += "</table>"
            hasContent = true
          }else if(obj.name){
            content += "<div style='font-size:18px;padding:2px 5px;font-weight:bold;border-bottom:1px solid #f2f2f2;margin-bottom:5px;'>"+obj.name+"</div>"
            content += "<table style='width:100%;'>"
            if(options.location_roles){
              content += createContentItem("field.role", (options.location_roles[obj.role] ? options.location_roles[obj.role].text : obj.role))
            }
            content += "</table>"
            hasContent = true
          }
        }catch(err){
          console.log(err)
        }

      }else if(obj.marker && obj.marker.label){
        content += "<div style='font-size:18px;padding:2px 5px;font-weight:bold;border-bottom:1px solid #f2f2f2;margin-bottom:5px;'>"+obj.marker.label+"</div>"
        content += "<table style='width:100%;'>"
        content += createContentItem("coordinate", MagbizGeneral.toMoney(obj.marker.lat,7)+", "+MagbizGeneral.toMoney(obj.marker.lng,7))
        content += "</table>"
        hasContent = true
      }

      content += "</div>"

      if(hasContent){
        this.infoWindow.setContent(content)
        this.infoWindow.setPosition(new window.google.maps.LatLng(obj.marker.lat, obj.marker.lng))
        this.infoWindow.open(map)

        if(this.callback.markerClick){
          this.callback.markerClick(marker, obj)
        }
      }

    })

    window.google.maps.event.addListener(marker, "dragend", (e) => {
      if(this.callback.markerDragEnd){
        this.callback.markerDragEnd(marker, obj, {lat:e.latLng.lat(), lng:e.latLng.lng()})
      }
    })

  	return {marker:marker, label:label, caption:caption, radius:radius}
  }

  static clearMarkers(options){
    if(this.mapObjs[options.group]){
  		this.clearData(options.group)
  	}
    if(this.mapObjs[options.group+"_label"]){
  		this.clearData(options.group+"_label")
  	}
    if(this.mapObjs[options.group+"_caption"]){
  		this.clearData(options.group+"_caption")
  	}
    if(this.mapObjs[options.group+"_radius"]){
  		this.clearData(options.group+"_radius")
  	}
    if(this.mapObjs[options.group+"_cluster"]){
  		this.clearData(options.group+"_cluster")
  	}
  }
/*
  static hideMarker(map, group, obj){
  	const objMarker = this.getMapObjInfo(group, obj.id);
  	if(objMarker.index !== -1){
  		this.mapObjs[group][objMarker.index].setMap(null);
  	}
  }

  static animateMoveMarker(map, oldObj, obj, duration, callback){

  	this.hideMarker(map, 'vehicle', obj);
  	if(obj.animateMarker)
  		obj.animateMarker.setMap(null);

  	var startPos = new google.maps.LatLng(oldObj.lat, oldObj.lng);
  	var endPos = new google.maps.LatLng(obj.lat, obj.lng);

  	var routes = [];
  	routes.push(startPos);
  	routes.push(endPos);

  	var angle = google.maps.geometry.spherical.computeHeading(startPos,endPos);
  	if(oldObj.vehicle_state == "ON_STOP" && obj.vehicle_state == "ON_STOP")
  		angle = parseInt(obj.angle);
  	var rotate = "rotation: "+angle+"deg;transform: rotate("+angle+"deg);-webkit-transform: rotate("+angle+"deg);-moz-transform: rotate("+angle+"deg);filter: progid:DXImageTransform.Microsoft.BasicImage(rotation="+(angle/90)+");";
  	var blink_color = "";

  	var labelText = "";
  	var labelTextShort = "";
  	if(MagbizGeneral.value(obj.icon_label) == ""){
  		labelText = obj.regist_no;
  		labelTextShort = obj.regist_no;
  	}
  	if(config.fleetMonitoringMode == "FleetWorking"){
  		if(obj.vehicle_state == "ON_RUN"){
  			blink_color = "rgba(0,255,0,0.2)";
  			labelText += "<div style='white-space:nowrap;'>"+uitext.cstate_normal_run+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  			labelTextShort += "<div style='white-space:nowrap;'>"+uitext.cstate_speed+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  		}else if(obj.vehicle_state == "ON_RUN_EXCEED"){
  			blink_color = "rgba(255,0,0,0.2)";
  			labelText += "<div style='white-space:nowrap;'>"+uitext.cstate_over_run+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  			labelTextShort += "<div class='red-text' style='white-space:nowrap;'>"+uitext.cstate_speed+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  		}else if(obj.vehicle_state == "ON_STOP"){
  			blink_color = "rgba(0,0,255,0.2)";
  			var diff = Math.round(General_diffDate(Cloud_syncedServerTime(),obj.vehicle_state_time)/1000/60);
  			labelText += "<div style='white-space:nowrap;'>"+uitext.cstate_stop+" "+(diff > 0 ? uitext.about+" "+diff+" "+uitext.unit_min : "")+"</div>";
  			labelTextShort += "<div style='white-space:nowrap;'>"+uitext.cstate_stop+" "+(diff > 0 ? uitext.about+" "+diff+" "+uitext.unit_min : "")+"</div>";
  		}
  	}else if(config.fleetMonitoringMode == "FleetEvent"){
  		if(obj.vehicle_state == "ON_RUN"){
  			blink_color = "rgba(0,255,0,0.2)";
  			labelText += "<div style='white-space:nowrap;'>"+(oldObj.vehicle_state == "OFF" ? uitext.cstate_start_engine+" "+uitext.cstate_start_run : (oldObj.vehicle_state == "ON_STOP" ? uitext.cstate_start_run : uitext.cstate_normal_run) )+"</div>";
  		}else if(obj.vehicle_state == "ON_RUN_EXCEED"){
  			blink_color = "rgba(255,0,0,0.2)";
  			labelText += "<div style='white-space:nowrap;'>"+uitext.cstate_over_run+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  		}else if(obj.vehicle_state == "ON_STOP"){
  			blink_color = "rgba(0,0,255,0.2)";
  			labelText += "<div style='white-space:nowrap;'>"+(oldObj.vehicle_state == "OFF" ? uitext.cstate_start_engine : uitext.cstate_stop )+" </div>";
  		}else if(obj.vehicle_state == "OFF"){
  			blink_color = "rgba(189,183,191,0.2)";
  			labelText += "<div style='white-space:nowrap;'>"+uitext.cstate_off+"</div>";
  		}
  	}else{
  		if(obj.vehicle_state == "ON_RUN"){
  			blink_color = "rgba(0,255,0,0.2)";
  			labelTextShort += "<div style='white-space:nowrap;'>"+uitext.cstate_speed+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  		}else if(obj.vehicle_state == "ON_RUN_EXCEED"){
  			blink_color = "rgba(255,0,0,0.2)";
  			labelTextShort += "<div class='red-text' style='white-space:nowrap;'>"+uitext.cstate_speed+" "+obj.speed +" "+uitext.unit_km+"/"+uitext.unit_hour+"</div>";
  		}else if(obj.vehicle_state == "ON_STOP"){
  			blink_color = "rgba(0,0,255,0.2)";
  			var diff = Math.round(General_diffDate(Cloud_syncedServerTime(),obj.vehicle_state_time)/1000/60);
  			labelTextShort += "<div style='white-space:nowrap;'>"+uitext.cstate_stop+" "+(diff > 0 ? uitext.about+" "+diff+" "+uitext.unit_min+"" : "")+"</div>";
  		}
  	}

  	var vehicle_content = "<div style='position:relative;'>";
  	vehicle_content += "	<img src='"+obj.vehicle_img_origin+"' class='bgo_vehicle_img2 blinkcolor' style='"+(blink_color!="" ? "background-color:"+blink_color+";":"")+rotate+"'/>";
  	vehicle_content += "	<div class='bgo_label_marker2' >"+(oldObj.target ? General_value(obj.icon_label)+labelText : General_value(obj.icon_label)+labelTextShort )+"</div>";
  	vehicle_content += "</div>";

  	obj.animateMarker = new MarkerWithLabel({
  			position: startPos,
  			map: map,
  			title: obj.icon_label,
  			labelAnchor: new google.maps.Point(25, 25),
  			labelContent: vehicle_content,
  			icon:  {
  				url: "img/vehicles/point_"+(obj.vehicle_state == "ON_RUN" ? "green" : "red" )+".png",
  				origin: new google.maps.Point(0, 0),
  				anchor: new google.maps.Point(8, 8)
  			}
  	});
  	obj.animateMarker.setDuration(duration);
  	obj.animateMarker.setEasing("linear");
  	obj.animateMarker.setPosition(endPos);
  	setTimeout(function(){
  		obj.animateMarker.setMap(null);
  		//line.setMap(null);
  		delete marker;
  		//delete line;
  		callback();
  	},duration+1000);

  }
  */
  // ################# Polygon
  static addPolygon(map, obj, options){
  	const geoObj = this.placePolygon(map, obj, options);
    if(geoObj){
      this.keepData(geoObj.polygon, options.group, "add")
      if(geoObj.label){
        this.keepData(geoObj.label, options.group+"_label", "add")
      }
    }
  }

  static removePolygon(obj, options){
  	const geoObj = this.getMapObjInfo(options.group,obj.id);
  	if(geoObj.index !== -1) {
  		this.clearMapObj(options.group, geoObj.index)
      this.clearMapObj(options.group+"_label", geoObj.index)
  	}
    const geoObj1 = this.getMapObjInfo(options.group+"_overlap",obj.id);
  	if(geoObj1.index !== -1) {
      this.clearMapObj(options.group+"_overlap", geoObj1.index)
    }
  }

  static drawPolygons(map, objs, options){
  	this.keepData([], options.group);
    this.keepData([], options.group+"_label");
    this.keepData([], options.group+"_overlap");
    if(objs){
      for(let i=0;i<objs.length;++i){
        if(objs[i].status !== "Cancelled"){
          let geoObj = this.placePolygon(map, objs[i], options);
          if(geoObj){
  			     this.keepData(geoObj.polygon, options.group, "add")
             if(geoObj.label){
               this.keepData(geoObj.label, options.group+"_label", "add")
             }
             if(objs[i].percentOfIntersect > 0){
               const geoOverlap = this.placeMarker(map, {
                 id:objs[i].id,
                 lat:objs[i].lat,
                 lng:objs[i].lng,
                 marker:{
                   image:window.location.origin+"/assets/"+settings.name+"/img/field_overlap.png",
                   width:32,
                   height:32,
                   anchor:"Center",
                   lat:objs[i].lat,
                   lng:objs[i].lng,
                   scale:1
                 }
               }, {group:options.group+"_overlap"})
               if(geoOverlap){
                 this.keepData(geoOverlap.marker, options.group+"_overlap", "add")
               }
             }
          }else if(objs[i].lat && objs[i].lng){
            const geoPointField = this.placeMarker(map, {
              ...objs[i],
              marker:{
                image:window.location.origin+"/assets/"+settings.name+"/img/markers/field.png",
                width:32,
                height:37,
                scale:(options.hideLabel ? 0.2 : 0.5),
                label:objs[i].field_no,
                lat:objs[i].lat,
                lng:objs[i].lng,
                isField:true
              }
            }, options)
            if(geoPointField){
              this.keepData(geoPointField.marker, options.group, "add")
              if(geoPointField.label){
                this.keepData(geoPointField.label, options.group+"_label", "add")
              }
            }
          }
        }
    	}
    }
  }

  static placePolygon(map, obj, options) {
    const pts = Geo.getPathFromPolygon(obj.polygon_geofield)
    if(pts.length === 0) return null
    const centroid = Geo.getCentroidPolygonPath(pts)
  	let points = [];
  	let bounds = new window.google.maps.LatLngBounds();
  	for(let k=0;k<pts.length;++k){
  		points.push(new window.google.maps.LatLng(pts[k].lat, pts[k].lng));
  		bounds.extend(new window.google.maps.LatLng(pts[k].lat, pts[k].lng));
  	}
  	if(!obj.color) obj.color = "blue";
    let color = (options.status_list && obj.status && !obj.forceColor ? options.status_list[obj.status].color : obj.color)
    if(obj.harvest_type !== "" && obj.status === "Harvest"){
      if(obj.field_no && obj.field_no.indexOf("นอกแผน") !== -1){
        color = "blue"
      }else if(options && options.master_fixed && options.master_fixed.harvest_type_object && options.master_fixed.harvest_type_object[obj.harvest_type].color){
        color = options.master_fixed.harvest_type_object[obj.harvest_type].color
      }
    }
  	let polygon = new window.google.maps.Polygon({
  		map: map,
  		strokeColor: color,
  		strokeOpacity: 0.6,
  		strokeWeight: (obj.focus ? 10 : 3),
  		fillColor: color,
  		fillOpacity: (obj.geometry_type === "zone" ? 0.05 : 0.35 ),
  		paths: points,
  		position: bounds.getCenter(),
      clickable: (obj.geometry_type === "zone" || obj.geometry_type === "field" || !options.hideLabel ? false : true),
      editable: (obj.editable ? true : false),
      draggable: (obj.editable ? true : false),
      zIndex:(obj.geometry_type === "zone" ? 0 : (obj.geometry_type === "poi" ? 2 : 1))
  	});
  	polygon.id = obj.id;

    let label = null
    if(!options.hideLabel){
      const labelStyle = "white-space:nowrap;overflow: hidden;text-overflow: ellipsis;font-size:11px;font-weight:bold;max-width:140px;padding:2px 5px;color:white;border-radius:5px;text-align:center;"
      label = new CustomMarker(new window.google.maps.LatLng(obj.lat, obj.lng), map, {
        content: "<div style='"+labelStyle+";color:#888;background-color:rgba(255,255,255,0.7);'>"+(obj.field_no ? obj.field_no : obj.name)+"</div>",
        offsetLeft: -10,
        offsetTop: -3,
        clickable:false
      })
      window.google.maps.event.addListener(label, "click", (e) => {
        if(this.infoWindow) {this.infoWindow.setMap(null)}
        this.infoWindow = new window.google.maps.InfoWindow()
        this.infoWindow.setContent(createContent())
        this.infoWindow.setPosition(new window.google.maps.LatLng(centroid.lat, centroid.lng))
        this.infoWindow.open(map)
      })
      label.id = obj.id
    }

    if(polygon){
      window.google.maps.event.addListener(polygon, "click", (e) => {
        if(this.infoWindow) {this.infoWindow.setMap(null)}
        this.infoWindow = new window.google.maps.InfoWindow()
        this.infoWindow.setContent(createContent())
        this.infoWindow.setPosition(new window.google.maps.LatLng(centroid.lat, centroid.lng))
        this.infoWindow.open(map)

        if(this.callback.polygonClick){
          this.callback.polygonClick(polygon, obj)
        }
      })
    }

    window.google.maps.event.addListener(polygon, "dragend", () => {
      if(this.callback.polygonDragEnd){
        this.callback.polygonDragEnd(polygon, obj)
      }
    });

    polygon.enableCoordinatesChangedEvent()
    window.google.maps.event.addListener(polygon, 'coordinates_changed', () => {
      if(this.callback.polygonCoordinatesChanged){
        this.callback.polygonCoordinatesChanged(polygon, obj)
      }
    });

    const createContent = () => {
      let content = "<div style='min-width:300px;'>"

      try{
        if(options.master_fixed && obj.field_no){
          content += "<div style='font-size:18px;padding:2px 5px;font-weight:bold;border-bottom:1px solid #f2f2f2;margin-bottom:5px;'>"+obj.field_no+"</div>"
          content += "<table style='width:100%;'>"
          content += createContentItem("field.yearly", obj.yearly)
          content += createContentItem("field.doc_area", MagbizGeneral.toMoney(obj.doc_area,1)+" ไร่")
          content += createContentItem("field.gps_area", MagbizGeneral.toMoney(obj.gps_area,1)+" ไร่")
          content += createContentItem("field.produce_weight", MagbizGeneral.toMoney(obj.produce_weight,1)+" ตัน")
          content += createContentItem("field.farmer", (obj.farmer_name ? obj.farmer_quota+" - "+obj.farmer_name : ""))
          content += createContentItem("field.staff", obj.staff_name)
          if(options.master_fixed.plant_type_object && options.master_fixed.plant_type_object[obj.plant_type]){
            content += createContentItem("field.plant_type2" , options.master_fixed.plant_type_object[obj.plant_type].text )
          }else{
            content += createContentItem("field.plant_type" , obj.plant_type )
          }
          content += createContentItem("field.plant_age", (options.master_fixed.plant_age_object[obj.plant_age] ? options.master_fixed.plant_age_object[obj.plant_age].text : obj.plant_age))
          if(obj.jsn_info_brix_avg){
            content += createContentItem("farm.brix.avg_brix", obj.jsn_info_brix_avg)
          }
          content += createContentItem("field.coordinate" , obj.lat+", "+obj.lng )
          if(options && options.master_fixed && options.master_fixed.harvest_type_object && options.master_fixed.harvest_type_object[obj.harvest_type]){
            content += createContentItem("field.harvest_type", options.master_fixed.harvest_type_object[obj.harvest_type].text)
          }
          if(options.master_fixed.field_status_object[obj.status]){
            content += createContentItem("status", options.master_fixed.field_status_object[obj.status].text, options.master_fixed.field_status_object[obj.status].color)
          }
          content += "</table>"
        }else{
          content += "<div style='font-size:18px;padding:2px 5px;font-weight:bold;border-bottom:1px solid #f2f2f2;margin-bottom:5px;'>"+obj.name+"</div>"
          content += "<table style='width:100%;'>"
          if(options.location_roles){
            content += createContentItem("field.role", (options.location_roles[obj.role] ? options.location_roles[obj.role].text : obj.role))
          }
          content += "</table>"
        }
      }catch(err){
        console.log(err)
      }

      content += "</div>"

      return content
    }

    const createContentItem = (label, value, color) => {
      if(!value) return ""
      if(options.translate || options.tlang){
        let content = "<tr>"
        content += "<td style='font-size:12px;padding:2px 5px;font-weight:bold;width:100px;'>"+(options.translate ? options.translate(label) : options.tlang({"id":label}))+"</td>"
        content += "<td style='font-size:12px;padding:2px 5px;width:5px;'> : </td>"
        content += "<td style='font-size:12px;padding:2px 5px;border-bottom:1px solid #fafafa;color:"+(color ? color : "#888")+";'>"+value+"</td>"
        content += "</tr>"
        return content
      }else{
        return ""
      }
    }

    window.google.maps.event.addListener(polygon, 'mouseover', function (e) {

    });

  	return {polygon:polygon, label:label};
  }

  static clearPolygons(options){
  	this.clearData(options.group)
    this.clearData(options.group+"_label")
  }

  static setDrawingMode(map, mode, callback){
    this.clearData({group:"field_working"})
    this.setCallback("polygonAddComplete", callback)
  	if(mode === "add"){
  		this.drawingManager.setOptions({
  			drawingMode: window.google.maps.drawing.OverlayType.POLYGON
  		})
  	}else{
  		this.drawingManager.setOptions({
  			drawingMode: null
  		})
  	}
  }

  static getPolygonPoints(polygon){
    if(!polygon) return []
    let points=[]
    if(polygon.getPath()){
      polygon.getPath().forEach(function(latLng){
        points.push({ lat: latLng.lat(), lng: latLng.lng()})
      });
    }

    let pts=[]
    for(let k=0;k<points.length;++k){
      let existing=false
      for(let j=0;j<pts.length;++j){
        if(points[k].lat === pts[j].lat && points[k].lng === pts[j].lng){
          existing = true
          break
        }
      }
      if(!existing){
        pts.push(points[k])
      }
    }

    return points
  }

  static calculatePolygonArea(polygon){
    if(!polygon) return 0
  	return parseFloat(window.google.maps.geometry.spherical.computeArea(polygon.getPath()));
  }

  //################ History ###################
  static drawHData(map, obj, routeOptions){
  	this.keepData([],routeOptions.group)
  	this.keepData([],routeOptions.group+"Symbol")
  	this.keepData([],routeOptions.group+"StartStop")

  	const bounds = this.placeHData(map, obj, routeOptions)
  	this.mapSetBound(map, bounds)

    const labelStyle = "white-space:nowrap;overflow: hidden;text-overflow: ellipsis;font-size:10px;padding:3px 5px;color:white;border-top-right-radius:5px;border-bottom-left-radius:5px;border-bottom-right-radius:5px;"
  	if(obj.length >= 1){
      const startMarker = new CustomMarker(new window.google.maps.LatLng(obj[0].lat, obj[0].lng), map, {
        content: "<div style='"+labelStyle+";background-color:green;'>Begin</div>",
        offsetLeft: 2,
        offsetTop: 2,
      })
      this.keepData(startMarker,routeOptions.group+"StartStop","add")
  	}
  	if(obj.length >= 2){
      const endMarker = new CustomMarker(new window.google.maps.LatLng(obj[obj.length-1].lat, obj[obj.length-1].lng), map, {
        content: "<div style='"+labelStyle+";background-color:#1664CD;'>End</div>",
        offsetLeft: 2,
        offsetTop: 2,
      })
      this.keepData(endMarker,routeOptions.group+"StartStop","add")
  	}
    if(routeOptions.notification){
      for(let i=0;i<obj.length;++i){
        if(obj[i].rtime >= routeOptions.notification.start_recorded_time){
          const startEventMarker = new CustomMarker(new window.google.maps.LatLng(obj[i].lat, obj[i].lng), map, {
            content: "<div style='"+labelStyle+";background-color:red;'>Notify Location</div>",
            offsetLeft: 2,
            offsetTop: 2,
          })
          this.keepData(startEventMarker,routeOptions.group+"StartStop","add")
          break
        }
      }
    }
  }

  static placeHData(map,obj,routeOptions){

  	let lineType = "A";
    let prevLineType = "A";
  	let bounds = [];
  	let routes = [];
  	let symboleRoutes = [];
  	let oldAngle = 0;
    let oldSpeed = 0;
  	for(let i=0;i<obj.length;++i){

  		let flagChange=false;
      if((obj[i].io_ELEVATOR === "ON" || obj[i].io_SWITCH === "ON") && lineType !== "H"){
        prevLineType = lineType
  			lineType = "H";
  			flagChange = true;
  		}else if(obj[i].speed > 0 && obj[i].speed > routeOptions.vehicle.limited_speed && lineType !== "B"){
        prevLineType = lineType
        lineType = "B";
  			flagChange = true;
  		}else if(obj[i].speed <= routeOptions.vehicle.limited_speed && lineType !== "A"){
        prevLineType = lineType
        lineType = "A";
  			flagChange = true;
  		}
  		if(routes.length > 0 && flagChange){
  			routes.push(new window.google.maps.LatLng(obj[i].lat, obj[i].lng));
  			let line = new window.google.maps.Polyline({
  				path: routes,
  				strokeColor:(prevLineType === "H" ? "#F1C40F" : (prevLineType === "B" ? "#97083C" : "#18900C")), //(lineType === "B" ? "#00FF40" : "#FF0000"),
  				strokeOpacity: 0.5,
  				strokeWeight: 8
  			})
  			line.setMap(map);
  			window.google.maps.event.addListener(line, 'click', (event) => {
  				this.goToClosestPoint(map, routeOptions.vehicle, obj, {lat:event.latLng.lat(), lng:event.latLng.lng()})
  			});
  			this.keepData(line, routeOptions.group, "add")
  			routes = [];
  		}

  		if((symboleRoutes.length >= 4 && obj[i].speed >= 10 && Math.abs(obj[i].angle-oldAngle) < 10 ) || (symboleRoutes.length > 0 && (i === 1 || i === obj.length - 1))){
  			//symboleRoutes.push(new window.google.maps.LatLng(obj[i].lat, obj[i].lng));
  			const arrow = new window.google.maps.Polyline({
  				path: symboleRoutes,
  				strokeColor: "white",
  				strokeOpacity: 1,
  				strokeWeight: 1.1,
  				icons: [{
            icon: {
      				path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW
      			},
            offset: '100%'
          }]
  			});
        setTimeout(() => {
          arrow.setMap(map)
        }, 100)
  			this.keepData(arrow,routeOptions.group+"Symbol","add");
  			symboleRoutes = [];
  		}

      if(oldSpeed !== 0 || obj[i].speed !== 0){
        routes.push(new window.google.maps.LatLng(obj[i].lat, obj[i].lng));
    		symboleRoutes.push(new window.google.maps.LatLng(obj[i].lat, obj[i].lng));
      }

  		oldAngle = obj[i].angle;
      oldSpeed = obj[i].speed;

  		if(i%10 === 0 || i === obj.length - 1) {
  			bounds.push(new window.google.maps.LatLng(obj[i].lat, obj[i].lng));
  		}

  	}

  	if(routes.length > 0){
  		let line = new window.google.maps.Polyline({
  			path: routes,
        strokeColor:(lineType === "H" ? "#F1C40F" : (lineType === "B" ? "#97083C" : "#18900C")),
        strokeOpacity: 0.5,
        strokeWeight: 8
  		});
  		line.setMap(map);
  		window.google.maps.event.addListener(line, 'click', (event) => {
  			this.goToClosestPoint(map, routeOptions.vehicle, obj, {lat:event.latLng.lat(), lng:event.latLng.lng()})
  		});
  		this.keepData(line,routeOptions.group,"add");
  	}

    if(symboleRoutes.length > 0){
      const arrow = new window.google.maps.Polyline({
        path: symboleRoutes,
        strokeColor: "white",
        strokeOpacity: 1,
        strokeWeight: 1.1,
        icons: [{
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW
          },
          offset: '100%'
        }]
      });
      setTimeout(() => {
        arrow.setMap(map)
      }, 100)
      this.keepData(arrow,routeOptions.group+"Symbol","add")
    }

  	return bounds;
  }

  static clearHData(map, routeOptions){
  	this.clearData(routeOptions.group);
  	this.clearData(routeOptions.group+"Symbol");
  	this.clearData(routeOptions.group+"StartStop")
  }

  //############## Other #################
  static drawArrowPath(map, obj, routeOptions){
    this.keepData([],routeOptions.group)

    if(obj.length > 0){
      let path = []
      for(let i=0;i<obj.length;++i){
        path.push({ lat: obj[i].lat, lng: obj[i].lng })
      }

      const line = new window.google.maps.Polyline({
        path: path,
        strokeColor: (routeOptions.color ? routeOptions.color : "green"),
        strokeOpacity: 0.8,
        strokeWeight: 2.5,
        icons: [
          {
            icon: {
              path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
            },
            offset: "100%",
          },
        ],
        map: map,
      });

      this.keepData(line,routeOptions.group,"add")
    }

  }

  static drawRoutePaths(map, obj, routeOptions){
    this.keepData([],routeOptions.group)

    if(obj.length > 0){

      for(let i=0;i<obj.length;++i){
        const line = new window.google.maps.Polyline({
          path: obj[i].path,
          strokeColor: (routeOptions.color ? routeOptions.color : "green"),
          strokeOpacity: (routeOptions.strokeOpacity ? routeOptions.strokeOpacity : 0.8),
          strokeWeight: (routeOptions.strokeWeight ? routeOptions.strokeWeight : 1.5),
          map: map,
        });

        this.keepData(line,routeOptions.group,"add")
      }


    }

  }

  static goToClosestPoint(map, vehicle, historys, location){
    let minDist = Number.MAX_VALUE
    let index = 0
    for (let i=0; i<historys.length; i++){
      const distance = this.calculateDistance(location.lat, location.lng, historys[i].lat, historys[i].lng)
      if (distance < minDist) {
        minDist = distance
        index = i
      }
    }
    this.pointToPositionHistory(map, vehicle, historys, index)
  }

  static pointToPositionHistory(map, vehicle, obj, index){
  	this.currentSimulate = index
  	//this.startSimulatePositionHistory(map, vehicle, obj, this.isSimulating());
  }

  static isSimulating(){
  	return this.runSimulate
  }

  static calculateDistance(lat1, lng1, lat2, lng2){
    const point1 = new window.google.maps.LatLng(lat1, lng1)
    const point2 = new window.google.maps.LatLng(lat2, lng2)
    return window.google.maps.geometry.spherical.computeDistanceBetween(point1, point2) //Meter
  }

  // ใช้งานไม่ได้ Google Maps API ไม่รองรับการเรียกโดยตรงจากเบราว์เซอร์ (Client-side)
  // สำหรับบาง API เช่น Directions API เพราะไม่ได้ตั้งค่าหัวข้อ CORS
  static async routing(source, dest) {
    const url = `https://maps.googleapis.com/maps/api/directions/json`;

    // สร้างพารามิเตอร์สำหรับคำขอ
    const params = {
      origin: `${source.lat},${source.lng}`,
      destination: `${dest.lat},${dest.lng}`,
      mode: "driving", // การเดินทางแบบขับรถ
      language: "th",  // ภาษาไทย
      key: GOOGLE_API_KEY,
    };

    try {
      // ส่งคำขอไปยัง Google Directions API
      const response = await axios.get(url, { params });
      const data = response.data;

      if (data.status !== "OK") {
        throw new Error(data.error_message || "Unable to fetch directions");
      }

      // ดึงข้อมูลเส้นทาง
      const route = data.routes[0];
      const legs = route.legs[0]; // Legs คือแต่ละช่วงของเส้นทางระหว่างจุดเริ่มต้นและจุดหมาย

      // สร้าง path จากข้อมูลพิกัด
      const path = legs.steps.map((step) => ({
        lat: step.end_location.lat,
        lng: step.end_location.lng,
      }));

      // เพิ่มจุดเริ่มต้นและจุดสิ้นสุด
      path.unshift({ lat: source.lat, lng: source.lng });
      path.push({ lat: dest.lat, lng: dest.lng });

      // คืนค่าข้อมูล
      return {
        source,
        dest,
        distance: legs.distance.value, // ระยะทางในหน่วยเมตร
        interval: legs.duration.value, // เวลาเดินทางในหน่วยวินาที
        path,
      };
    } catch (error) {
      console.error("Error fetching directions:", error);
      //throw error; // โยนข้อผิดพลาดกลับไปให้ผู้เรียกจัดการ
    }
    return null
  }

}

const CustomMarker = (latlng, map, args) => {

  function CustomOverlay (latlng, map, args){
    this.latlng = latlng;
    this.args = args;
    this.setMap(map);
  }

  CustomOverlay.prototype = new window.google.maps.OverlayView();
  CustomOverlay.prototype.draw = function() {
    var self = this;
    var div = this.div;
    if (!div) {
      div = this.div = document.createElement('div');
      div.className = 'marker';
      div.style.position = 'absolute';
      div.style.cursor = 'pointer';
      div.innerHTML = self.args.content;
      if (typeof(self.args.id) !== 'undefined') {
        div.dataset.id = self.args.id;
      }
      window.google.maps.event.addDomListener(div, "click", function(event) {
        window.google.maps.event.trigger(self, "click");
      });
      var panes = this.getPanes();
      panes.overlayImage.appendChild(div);
    }
    var point = this.getProjection().fromLatLngToDivPixel(this.latlng);
    if (point) {
      div.style.left = (point.x + (typeof self.args.offsetLeft !== "undefined" ? self.args.offsetLeft : 0)) + 'px';
      div.style.top = (point.y + (typeof self.args.offsetTop !== "undefined" ? self.args.offsetTop : 0)) + 'px';
    }
  };
  CustomOverlay.prototype.remove = function() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  };
  CustomOverlay.prototype.getPosition = function() {
    return this.latlng;
  };

  return new CustomOverlay(latlng, map, args)
}
