import React,{Fragment} from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import { withRouter } from "react-router";
import classNames from 'classnames';
import MaterialIcon from 'react-material-iconic-font'
import { withStyles } from '@material-ui/core/styles';

import {AppBar, Typography, Toolbar, Grid, CircularProgress, Divider} from '@material-ui/core';
import SectionList from '../../navigation/Drawer/components/SectionList';

import '../../theme/css/ReportViewer.css';
import '../../theme/css/ReportTable.css';
import {actionPerform as actionPerformInfo} from '../../redux/Info'
import {actionPerform as actionPerformApp} from '../../redux/App'
import settings from '../../settings'
import {KTSelectField, KTDateField, KTTimeField, KTTextField, KTButton, KTDownloadExcel, KTDownloadCsv, KTWGoogleChart} from '../../KT'
import {MagbizUI,MagbizGeneral,MagbizDateTime} from '../../utils/Magbiz'

const drawerWidth = 240;

const styles = theme => {
  return {
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    subText:{
      fontSize: '0.7rem',
      backgroundColor:"white"
    },
    menuItemPrimaryText:{
      color:theme.palette.secondary.contrastText,
      fontSize: '0.8rem'
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }
  }
};

class UIReportViewer extends React.Component {

  state = {
    selectedItem: null,
    filterHiddens: Object.values(this.props.module.filter).reduce((object, item) => {
      if(item.isHide){object[item.name] = true}
      return object
    }, {}),
    export_csv: true,
    export_csv_separator: true,
    export_excel: true,
    toggleClear: false
  }

  componentDidMount(){
    this._onQueryString()
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.module !== nextProps.module){return true}
    if(this.props.app.screen !== nextProps.app.screen){return true}
    if(this.props.app.lang !== nextProps.app.lang){return true}
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){return true}
    if(this.props.info.action[this.props.module.name] !== nextProps.info.action[this.props.module.name]){return true}
    if(this.props.info.filters[this.props.module.name] !== nextProps.info.filters[this.props.module.name]){
      //this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.name})
      return true
    }
    if(this.state !== nextState){return true}
    return false
  }

  onItemSelected = (item) => {
    this.props.info[this.props.module.name] = null
    this.setState({selectedItem: item})
    if(this.props.module.onItemSelected){
      const result = this.props.module.onItemSelected(item)
      if(result){
        if(result.filterHiddens){
          this.setState({filterHiddens: result.filterHiddens})
        }
        if(typeof result.export_csv !== "undefined"){
          this.setState({export_csv: result.export_csv})
        }
        if(typeof result.export_csv_separator !== "undefined"){
          this.setState({export_csv_separator: result.export_csv_separator})
        }
        if(typeof result.export_excel !== "undefined"){
          this.setState({export_excel: result.export_excel})
        }
        if(result.clearFilters && this.props.info.filters[this.props.module.name]){
          const thefilter = {...this.props.info.filters[this.props.module.name]}
          for(let k in result.clearFilters){
            const item = result.clearFilters[k]
            thefilter[k].value = item.value
          }
          this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter:thefilter})
          this.setState({toggleClear: !this.state.toggleClear})
        }
      }
    }
  }

  _onQueryString = () => {
    const reportname = window.location.pathname.replace(this.props.match.path,"").replace("/","")
    if(reportname !== ""){
      Object.entries(this.props.module.menu).map((item, index) => {
        if(item[1]){
          item[1].list.map((item1, index1) => {
            if(item1.child_routes){
              item1.child_routes.map((item2, index2) => {
                if((reportname+window.location.search).startsWith(item2.path.replace("report/",""))){
                  const params = window.location.search.replace("?","").split("&")
                  let filter = (this.props.info.filters[this.props.module.name] ? {...this.props.info.filters[this.props.module.name]} : {...this.props.module.filter})
                  if(filter){
                    for(let k=0;k<params.length;++k){
                      const part = params[k].split("=")
                      if(part.length === 2){
                        if(filter[part[0]]){
                          filter[part[0]].value = part[1]
                          filter[part[0]].text = (this.props.module.list && this.props.module.list[part[0]] ? this.props.module.list[part[0]][part[1]].text : part[1])
                        }
                      }
                    }
                  }
                  this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter: filter })
                  this.onItemSelected(item2)
                  MagbizGeneral.delay(() => {
                    this.loadData()
                  }, 1000)
                }
                return null
              })
            }
            return null
          })
        }
        return null
      })
    }
  }

  _onRowCick = (link) => {
    if(link){
      window.open(link)
    }
  }

  _onFilterDateTimeChange = (dateObj) => {
    this.onFilterChange(dateObj)
  }

  _onFilterSelectChange = (event) => {
    this.onFilterChange(event.target)
  }

  _onFieldChange = (event) => {
    this.onFilterChange(event.nativeEvent.target)
  }

  onFilterChange = (target) => {
    if(this.props.module.filter.hasOwnProperty(target.name)){
      let filter = (this.props.info.filters[this.props.module.name] ? {...this.props.info.filters[this.props.module.name]} : {...this.props.module.filter})
      filter[target.name].value = target.value
      filter[target.name].text = (typeof target.label !== 'undefined' ? target.label : target.value)
      this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter: filter })
    }
  }

  parseDataExcel = (item, index) => {
    let objects = {}
    for(let i=0;i<item.length;i++){
      let value = "";
      if(typeof item[i] === 'object') {
        if(item[i].type === "date"){
          value = Math.ceil(MagbizDateTime.dateDiff(item[i].value+" 00:00:00", "1900-01-01 00:00:00"))+2
        }else{
          if(item[i].value !== false) value = item[i].value
        }
      }else{
        if(item[i] !== false) value = item[i]
      }
      value = MagbizUI.textFromHTML(value)
      objects["field"+i] = value
    }
    return objects
  }

  parseDataCsv = (item, index) => {
    let objects = {}
    for(let i=0;i<item.length;i++){
      let value = "";
      if(typeof item[i] === 'object') {
        if(item[i].value !== false) value = item[i].value
      }else{
        if(item[i] !== false) value = item[i]
      }
      objects["field"+i] = value
    }
    return objects
  }

  loadData = () => {
    if(this.state.selectedItem){
      const filter = (this.props.info.filters[this.props.module.name] ? this.props.info.filters[this.props.module.name] : {...this.props.module.filter})
      const filter_fields = MagbizUI.getDataFromFilterItem(filter)
      this.props.actionPerformInfo("INFO_OPERATION", {action:this.state.selectedItem.path, body:filter_fields, fdata:this.props.module.name})
    }
  }

  onExportStampTime = (filename) => {
    return filename+"_"+MagbizDateTime.nowFormat("YYYYMMDDHHmm")
  }

  render() {
    //console.log("Render UIReportViewer : "+this.props.module.name)
    const {classes} = this.props
    const filters = this.props.info.filters
    const tlang = this.props.intl.formatMessage

    const page_name = tlang({"id":"sidebar.report"})//tlang({"id":"sidebar."+this.props.match.path.replace("/"+settings.name+"/","").replace("/detail","").replace("/","_")})
    const docWidth = (this.props.width ? this.props.width : this.props.app.screen.width)
    const showSideBar = true //(this.props.module.menu && docWidth >= 1280 ? true : false)
    const docContentWith = docWidth - (showSideBar ? drawerWidth : 0)
    const docContentHeight = (this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2)

    const data = this.props.info[this.props.module.name]
    let columns = null
    let excelcolumns = null
    let allowCsv = false
    if(data){
      columns = data.cols;
      excelcolumns = data.cols;
      if(data.cols2){
        columns = data.cols2;

        let thecol = []
        for(let k=0;k<data.cols.length;++k){
          if(data.cols[k].colspan){
            for(let c=0;c<data.cols[k].colspan;++c){
              thecol.push(data.cols[k])
            }
          }else{
            thecol.push(data.cols[k])
          }
        }

        excelcolumns = []
        for(let k=0;k<data.cols2.length;++k){
          excelcolumns.push({...data.cols2[k], name:(data.cols2[k].name ? data.cols2[k].name+" ("+thecol[k].name+")" : thecol[k].name)})
        }

      }else if(this.props.module.export_csv && this.state.export_csv){
        allowCsv = true
      }
    }
    const filter = (this.props.info.filters[this.props.module.name] ? this.props.info.filters[this.props.module.name] : {...this.props.module.filter})

    const createMarkup = (text) => {
      return {__html: text};
    }

    const size = (this.state.selectedItem ? this.state.selectedItem.size : "")

    return (
      <Fragment>
        <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark,zIndex: 900}}>
          <Toolbar >
            <div className={classes.sectionDesktop} style={{flexDirection:'column', justifyContent:'center', marginRight:10}}>
              <Grid container direction="column" justify="center">
                <Typography variant="subtitle1" color="inherit" >
                  {page_name}
                </Typography>
              </Grid>
            </div>
            <div className={classes.sectionDesktop} style={{marginLeft:10, marginRight:10}}>
              <MaterialIcon type={"filter-list"} />
            </div>

            <div className={classes.sectionMobile} style={{flexGrow: 1}} />

            {this.props.module.filter && Object.values(this.props.module.filter).map((item, index) => {
              if(item === null) return null
              //if(item.isHide) return null
              if(this.state.filterHiddens[item.name])  return null
              switch(item.type) {
                 case "select": {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                       <KTSelectField name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder={item.placeholder ? item.placeholder : " "} align="center"
                         icon={item.icon} defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                         suggestions={item.list} async={Boolean(item.async)} isMulti={item.isMulti}
                         onChange={(e) => this._onFilterSelectChange(e)} small light />
                     </div>
                   )
                 }
                 case "text": {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                        <KTTextField name={item.name} type="text"  label={tlang({"id":(item.caption ? item.caption : item.name )})}  placeholder={item.placeholder} align="center"
                           defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                           onChange={(e) => this._onFieldChange(e)} small light outline />
                     </div>
                   )
                 }
                 case "date" : {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                       <KTDateField type="date" name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" "  align="center"
                         defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                         onChange={(e) => this._onFilterDateTimeChange(e)} small light outline />
                     </div>
                   )
                 }
                 case "time": {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                       <KTTimeField type="time" name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" "  align="center"
                         defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                         onChange={(e) => this._onFilterDateTimeChange(e)} small light outline notblank />
                     </div>
                   )
                 }
                 default: {
                   return null
                 }
              }
            })}
            {this.state.selectedItem &&
            <div style={{display:'flex', marginLeft:5, marginRight:5}}>
              <KTButton size="small" onClick={this.loadData} style={{fontSize:"0.7rem", backgroundColor:settings.theme.primary.light}} loading={this.props.info.action[this.props.module.name]}  >
                {this.props.module.operation_text}
              </KTButton>
            </div>}
            <div className={classes.sectionDesktop} style={{flexGrow: 1}} />
            <div className={classes.sectionDesktop}>

              {this.props.module.export_excel && this.state.export_excel && this.state.selectedItem !== null && data && data.records &&
              <div style={{marginLeft:5, marginRight:5}}>
                <KTDownloadExcel filename={(this.state.selectedItem.exportfilename ? this.onExportStampTime(this.state.selectedItem.exportfilename)+".xlsx" : "รายงาน"+this.onExportStampTime(tlang({id:this.state.selectedItem.menu_title}).replace("รายงาน",""))+".xlsx")} columns={excelcolumns}
                  data={data.records.map((item, index) => {
                    return this.parseDataExcel(item, index)
                  })} datatype="report" docwidth={2000} />
              </div>}

              {allowCsv && this.state.selectedItem !== null && data && data.records &&
              <div style={{marginLeft:5, marginRight:5}}>
                <KTDownloadCsv filename={(this.state.selectedItem.exportfilename ? this.onExportStampTime(this.state.selectedItem.exportfilename)+".csv" : "รายงาน"+this.onExportStampTime(tlang({id:this.state.selectedItem.menu_title}).replace("รายงาน",""))+".csv")} columns={excelcolumns}
                  data={data.records.map((item, index) => {
                    return this.parseDataCsv(item, index)
                  })} separator={this.state.export_csv_separator} />
              </div>}

            </div>

          </Toolbar>
        </AppBar>

        {showSideBar && this.props.module.menu &&
        <div style={{width:drawerWidth-1, height:docContentHeight,
              maxHeight: docContentHeight, overflow: 'auto',
              float:"left", backgroundColor:"white", outline:"1px solid #f2f2f2"}}
          tabIndex={0}
          role="button">
          <div>
            {Object.entries(this.props.module.menu).map((item, index) => {
              return (
                <Fragment key={index}>
                  <Divider />
                  <SectionList menu={item[1]} type="item" selectedItem={this.state.selectedItem} onItemSelected={(item) => this.onItemSelected(item) } />
                </Fragment>
              )
            })}
          </div>
        </div>}

        <div className={classNames(classes.content, {
            [classes.contentShift]: showSideBar,
          })}>

          <div style={{
            width:docContentWith,
            height:docContentHeight,
            overflow:"auto",
            background: "rgb(204,204,204)"
          }}>

            <div  className={`document-container`}  ref={el => {
                this.props.actionPerformApp("APP_SETUP_INTERFACE", {...this.props.app.interface, printRef:el})
              }} >

              {this.props.info.action[this.props.module.name] &&
              <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'400px'}} >
                <CircularProgress />
              </Grid>}

              {this.state.selectedItem && data && !this.props.info.action[this.props.module.name] &&
              <div className={`document `+(size ? size : `A4landscape`)} style={{paddingTop:0, paddingBottom:0}}>
                <div className={`document-page `+(size ? size : `A4landscape`)} >

                  <div>
                    {!data.isChart ?
                    <table className='report_table'>
                      <thead>
                        <tr>
                          <th colSpan={columns.length} style={{textAlign:"right", paddingTop:"0.7cm"}}>
                            <div style={{fontSize:9,fontWeight:"normal"}}>{tlang({id:"report.print_date"})} : {data.report_time}</div>
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={columns.length} style={{textAlign:"center"}}>
                            <div style={{width:"100%", fontSize:18, fontWeight:"bold", marginBottom:5}}>
                              {(this.state.selectedItem.menu_title ? "รายงาน "+tlang({id:this.state.selectedItem.menu_title}).replace("รายงาน","") : "")}
                            </div>
                            <div style={{width:"100%",fontSize:11,fontWeight:"normal",marginBottom:20}}>
                              {data.filter_text}
                              {filter && Object.values(filter).map((item, index) => {
                                if(!this.state.filterHiddens[item.name] && !MagbizGeneral.includes([item.name],["startHdate","endHdate","startHtime","endHtime","start_date","end_date","start_time","end_time"])){
                                  if(item.value !== "" && item.value !== "undefined" && item.value !== "All" && item.value !== null){
                                    if(typeof item.text !== "undefined"){
                                      return ", "+item.text.replaceAll("|","+")
                                    }else{
                                      return ", "+item.value.replaceAll("|","+")
                                    }
                                  }
                                }
                                return ""
                              })}
                            </div>
                          </th>
                        </tr>
                        {data.cols &&
                        <tr className='column-header'>
                          {data.cols.map((item, index) => {
                            let attr = {}
                            for(let key in item){
                              if(MagbizGeneral.includes([key],["name","span","colspan","rowspan","width","align","norepeat"])) continue
                              if(key.indexOf("-") !== -1){
                                attr[key.substring(0,key.indexOf("-"))+MagbizGeneral.capitalize(key.substring(key.indexOf("-")+1,key.length))] = item[key]
                              }else{
                                attr[key] = item[key]
                              }
                            }
                            return (
                              <th key={index} style={{...attr, width:item.width}} colSpan={(item.colspan ? item.colspan : 1)} rowSpan={(item.rowspan ? item.rowspan : 1)} >{<div dangerouslySetInnerHTML={createMarkup(item.name)}/>}</th>
                            )
                          })}
                        </tr>}
                        {data.cols2 &&
                        <tr className='column-header'>
                          {data.cols2.map((item, index) => {
                            if(item.span) return null
                            let attr = {}
                            for(let key in item){
                              if(MagbizGeneral.includes([key],["name","span","colspan","rowspan","width","align","norepeat"])) continue
                              if(key.indexOf("-") !== -1){
                                attr[key.substring(0,key.indexOf("-"))+MagbizGeneral.capitalize(key.substring(key.indexOf("-")+1,key.length))] = item[key]
                              }else{
                                attr[key] = item[key]
                              }
                            }
                            return (
                              <th key={index} style={{...attr, width:item.width}} colSpan={(item.colspan ? item.colspan : 1)} rowSpan={(item.rowspan ? item.rowspan : 1)} >{<div dangerouslySetInnerHTML={createMarkup(item.name)}/>}</th>
                            )
                          })}
                        </tr>}
                      </thead>
                      <tbody>
                        {data.records && data.records.map((row_data, rindex) => {
                            let flagHidden = [false,false,false]
                            for(let k=0; k<columns.length; ++k){
                              if(columns[k].norepeat && row_data[k] && !row_data[k].colspan){
                                flagHidden[k] = true
                                if(columns[k].repeatOld !== row_data[k].value){
                                  columns[k].repeatOld = row_data[k].value
                                  flagHidden[k] = false
                                  if(k+1 < 6 && k+1 < columns.length){if(columns[k+1].norepeat){columns[k+1].repeatOld = row_data[k+1].value; flagHidden[k+1] = false}}
                                  if(k+2 < 6 && k+2 < columns.length){if(columns[k+2].norepeat){columns[k+2].repeatOld = row_data[k+2].value; flagHidden[k+2] = false}}
                                  if(k+3 < 6 && k+3 < columns.length){if(columns[k+3].norepeat){columns[k+3].repeatOld = row_data[k+3].value; flagHidden[k+3] = false}}
                                  if(k+4 < 6 && k+4 < columns.length){if(columns[k+4].norepeat){columns[k+4].repeatOld = row_data[k+4].value; flagHidden[k+4] = false}}
                                  if(k+5 < 6 && k+5 < columns.length){if(columns[k+5].norepeat){columns[k+5].repeatOld = row_data[k+5].value; flagHidden[k+5] = false}}
                                  break
                                }
                              }else if(row_data[k].colspan){
                                for(let h=0; h<6; ++h){
                                  columns[h].repeatOld = null
                                }
                                break
                              }
                            }

                            return (
                              <tr key={rindex}>
                                {row_data.map((item, k) => {

                                  let attr = {textAlign:(columns[k].align ? columns[k].align : "left"), color:"black"}
                                  if(!item.colspan){
                                    attr["width"] = columns[k].width
                                  }
                                  let value = "";
                                  if(typeof item === 'object') {
                                    if(item.value !== false) value = item.value
                                    for(let key in item.attr){
                                      if(key.indexOf("-") !== -1){
                                        attr[key.substring(0,key.indexOf("-"))+MagbizGeneral.capitalize(key.substring(key.indexOf("-")+1,key.length))] = item.attr[key]
                                      }else{
                                        attr[key] = item.attr[key]
                                      }
                                    }
                                  }else{
                                    if(item !== false) value = item
                                  }
                                  if(item.link){
                                    attr.cursor = "pointer"
                                    attr.textDecoration = "underline"
                                  }

                                  const divStyle = {}
                                  if(item.type === "decimal,0" || item.type === "int"){
                                    value = MagbizGeneral.toMoney(value, 0)
                                  }else if(item.type === "decimal,2"){
                                    value = MagbizGeneral.toMoney(value, 2)
                                  }else if(item.type === "decimal,3"){
                                    value = MagbizGeneral.toMoney(value, 3)
                                  }else if(item.type === "decimal,7"){
                                    value = MagbizGeneral.toMoney(value, 7)
                                  }else if(item.type === "photo_link"){
                                    if(value){
                                      value = "<a href='"+value+"' target='_blank'><img src='"+value+"' style='object-fit:contain;width:"+item.width+"px;height:"+item.height+"px;' /></a>"
                                      attr.height = item.height+"px"
                                      divStyle.height = item.height+"px"
                                    }
                                  }

                                  if(flagHidden[k]){
                                    value = ""
                                  }

                                  return (
                                    <td key={k} className={(row_data[0].line ? `line`+row_data[0].line : ``)} colSpan={item.colspan ? item.colspan : 1} style={attr} onClick={() => this._onRowCick(item.link)} >{
                                      <div dangerouslySetInnerHTML={createMarkup(value)} style={divStyle} />
                                    }</td>
                                  )

                                })}
                              </tr>
                            )
                          })
                        }
                        {data.records && data.records.length === 0 &&
                        <tr><td colSpan={columns.length} style={{color:"grey",textAlign:"center"}}>{tlang({id:"no_data"})}</td></tr>}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={columns.length} style={{textAlign:"left",border:"none"}}>
                            &nbsp;<br/>&nbsp;
                          </td>
                        </tr>
                      </tfoot>
                    </table> : null}
                    {data.isChart ? <KTWGoogleChart data={data} /> : null}
                    {data.hint &&
                    <div style={{fontSize:11,fontWeight:"normal",color:"red",marginBottom:40}} dangerouslySetInnerHTML={createMarkup(data.hint)} />}
                  </div>
                </div>
              </div>}

            </div>

          </div>

        </div>

      </Fragment>
    );

  }

}

export default withRouter(connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformInfo,
  actionPerformApp
})(injectIntl(withStyles(styles, { withTheme: true })(UIReportViewer))));
